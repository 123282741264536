import { useContext, useEffect, useMemo, useState } from 'react';
import languages from '../../__fixtures__/languages';
import { Link } from 'react-router-dom';
import Wait from '../UI/Wait';
import { Form, Button, Row, Col } from 'react-bootstrap';
import AmountInput from './components/AmountInput';
import getProduct from '../../api/utils/product/getProduct';
import { NotificationManager } from 'react-notifications';
import cn from 'classnames';
import getProductTypeList from '../../api/utils/product/type/getProductTypeList';
import UserInfoContext from '../../contexts/UserInfoContext';

const INIT_PRODUCT = {
    active: true,
    merchant: '',
    application: '',
    accessibility: 'private',
    settings: {
        str_name: 'The new product',
        str_name: '',
        str_name__da: '',
        str_name__en: '',
        str_name__fi: '',
        str_name__no: '',
        str_name__sv: '',
        amount: '',
        amount_vat: '',
        bool_visible: true,
        id_type: '',
        int_bonus_amount: '',
        int_bonus_value: '',
        str_bonus_type: '',
        str_description: '',
        str_description__da: '',
        str_description__en: '',
        str_description__fi: '',
        str_description__no: '',
        str_description__sv: '',
        str_ean: '',
        str_notes: '',
        str_unit_type: '',
        str_vendorcode: '',
        int_vat: '',
    },
};

const ACCESSABILITY_OPTIONS = [
    {
        label: 'Private',
        value: 'private',
    },
    {
        label: 'Public',
        value: 'public',
    },
];

const ProductEditForm = ({ id, onSubmit = () => {}, onDelete = () => {} }) => {
    const {
        merchant: { id: merchantId },
    } = useContext(UserInfoContext);

    const [language, setLanguage] = useState('');

    const [product, setProduct] = useState(INIT_PRODUCT);
    const [productTypes, setProductTypes] = useState([]);

    const [loading, setLoading] = useState(false);

    const postfix = useMemo(
        () => (language ? `__${language}` : ''),
        [language]
    );
    const postfixes = useMemo(
        () => ['', ...languages.map(({ value }) => `__${value}`)],
        []
    );

    useEffect(() => {
        if (id) {
            fetchProduct(id);
        } else {
            fetchProductTypes();
        }
    }, [id]);

    const fetchProduct = async (productId = '') => {
        const resultHandler = (data) => {
            setLoading(false);
            if (data && data.status === 'accept') {
                setProduct(data.product);
                setProductTypes(data.types);
            }
        };

        const errorHandler = (error) => {
            setLoading(false);
            NotificationManager.error(error.description, 'Error', 4000);
            setProduct(INIT_PRODUCT);
            setProductTypes([]);
        };

        try {
            setLoading(true);
            const res = await getProduct({ id: productId }, errorHandler);
            resultHandler(res);
        } catch (error) {
            setLoading(false);
            console.log('error fetching product: ', error);
        }
    };

    const fetchProductTypes = async () => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                setProductTypes(data.types);
            }
        };

        const errorHandler = (error) => {
            console.log('error getting product types: ', error);
            setProductTypes([]);
        };

        try {
            const res = await getProductTypeList(
                {
                    query_merchant: merchantId,
                    query_skip: 0,
                    query_count: 10000,
                },
                errorHandler
            );
            resultHandler(res);
        } catch (error) {
            console.log(error);
        }
    };

    const handleSubmitForm = (e) => {
        e.preventDefault();

        if (!product?.settings?.int_bonus_value) {
            product.settings.int_bonus_value = '0';
        }
        if (!product?.settings?.int_vat) {
            product.settings.int_vat = 0;
        }
        product.settings.int_bonus_value = +product.settings.int_bonus_value;

        onSubmit(product);
    };

    const handleChangeProductField = (e) => {
        setProduct((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleChangeSetting = (e) => {
        setProduct((prev) => {
            return {
                ...prev,
                settings: {
                    ...prev.settings,
                    [e.target.name]: e.target.value,
                },
            };
        });
    };

    const handleUpdatedSettings = (newSettings = {}) => {
        setProduct((prev) => ({
            ...prev,
            settings: {
                ...prev.settings,
                ...newSettings,
            },
        }));
    };

    return loading ? (
        <Wait />
    ) : (
        <Form onSubmit={handleSubmitForm}>
            <Row>
                <Form.Group
                    as={Col}
                    xs={12}
                    md={2}
                    className="mb-3"
                    controlId="data.bool_visible"
                >
                    <Form.Label>Visible</Form.Label>
                    <Form.Check
                        type="switch"
                        id="bool_visible"
                        name="bool_visible"
                        label="Is visible"
                        checked={!!product.settings.bool_visible}
                        onChange={(e) =>
                            handleChangeSetting({
                                target: {
                                    name: 'bool_visible',
                                    value: e.target.checked,
                                },
                            })
                        }
                    />
                    <Form.Text className="text-muted">
                        Change visibility on API
                    </Form.Text>
                </Form.Group>

                <Form.Group
                    as={Col}
                    xs={12}
                    md={6}
                    className="mb-3"
                    controlId="data.language"
                >
                    <Form.Label>Language</Form.Label>
                    <Form.Control
                        as={'select'}
                        aria-label="Language"
                        name="language"
                        value={language}
                        onChange={(e) => setLanguage(e.target.value)}
                        className="custom-select"
                    >
                        <option value="">Default</option>
                        {languages.map((item, index) => (
                            <option
                                key={`${index}-${item?.value}-${item?.name}-lang-option`}
                                value={item?.value}
                            >
                                {item?.name}
                            </option>
                        ))}
                    </Form.Control>
                    <Form.Text className="text-muted">
                        Select language for subject and message
                    </Form.Text>
                </Form.Group>

                <Form.Group
                    as={Col}
                    xs={12}
                    md={4}
                    className="mb-3"
                    controlId="data.accessibility"
                >
                    <Form.Label>Accessibility</Form.Label>
                    <Form.Control
                        as={'select'}
                        aria-label="Accessibility"
                        name="accessibility"
                        value={product.accessibility}
                        onChange={handleChangeProductField}
                        className="custom-select"
                    >
                        <option value="">Default</option>
                        {ACCESSABILITY_OPTIONS.map((item, index) => (
                            <option
                                key={`${index}-${item?.value}-${item?.label}-access-option`}
                                value={item?.value}
                            >
                                {item?.label}
                            </option>
                        ))}
                    </Form.Control>
                    <Form.Text className="text-muted">
                        Change accessibility
                    </Form.Text>
                </Form.Group>
            </Row>

            <Row>
                <Form.Group as={Col} xs={12} sm={8} controlId="data.name">
                    <Form.Label>Name</Form.Label>
                    {postfixes.map((current, index) => (
                        <Form.Control
                            key={`product-name-input-${index}-${current}`}
                            className={cn({
                                'd-none': postfix !== current,
                            })}
                            type="text"
                            name={`str_name${current}`}
                            placeholder="The product"
                            value={product.settings[`str_name${current}`]}
                            onChange={handleChangeSetting}
                        />
                    ))}
                    <Form.Text className="text-muted">
                        Product name {language ? `(${language})` : null}
                    </Form.Text>
                </Form.Group>

                <Form.Group as={Col} xs={12} sm={4} controlId="data.ean">
                    <Form.Label>EAN</Form.Label>
                    <Form.Control
                        type="text"
                        name="str_ean"
                        placeholder="The product"
                        value={product.settings.str_ean}
                        onChange={handleChangeSetting}
                    />
                    <Form.Text className="text-muted">EAN barcode</Form.Text>
                </Form.Group>
            </Row>

            <Row>
                <Col xs={12} sm={6}>
                    <Form.Group controlId="data.vendor_code">
                        <Form.Label>Vendor code</Form.Label>
                        <Form.Control
                            type="text"
                            name="str_vendorcode"
                            placeholder="Vendor code"
                            value={product.settings.str_vendorcode}
                            onChange={handleChangeSetting}
                        />
                    </Form.Group>

                    <Form.Group controlId="data.unit_type">
                        <Form.Label>Unit type</Form.Label>
                        <Form.Control
                            type="text"
                            name="str_unit_type"
                            placeholder="Unit type"
                            value={product.settings.str_unit_type}
                            onChange={handleChangeSetting}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="data.product_type">
                        <Form.Label>Type</Form.Label>
                        <Form.Control
                            as={'select'}
                            aria-label="Product type"
                            name="id_type"
                            value={product.settings?.id_type}
                            onChange={handleChangeSetting}
                            className="custom-select"
                        >
                            <option value="">Select product type</option>
                            {productTypes.map((item, index) => (
                                <option
                                    key={`${index}-${item?._id}-${item?.name}-type-option`}
                                    value={item?._id}
                                >
                                    {item?.name}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                </Col>

                <Form.Group as={Col} xs={12} sm={6} controlId="data.notes">
                    <Form.Label>Notes</Form.Label>
                    <Form.Control
                        type="text"
                        as="textarea"
                        rows={7}
                        name="str_notes"
                        placeholder="Notes"
                        value={product.settings.str_notes}
                        onChange={handleChangeSetting}
                    />
                    <Form.Text className="text-muted">Optional notes</Form.Text>
                </Form.Group>
            </Row>

            {product.merchant && (
                <Link to={`/products/tags/add?merchant=${product.merchant}`}>
                    Add tag
                </Link>
            )}

            <AmountInput
                settings={product.settings}
                setSettings={handleUpdatedSettings}
            />

            <Row>
                <Form.Group as={Col} xs={12} controlId="data.description">
                    <Form.Label>Description</Form.Label>

                    {postfixes.map((current, index) => (
                        <Form.Control
                            key={`descriptions-inputs-${index}`}
                            className={cn({
                                'd-none': postfix !== current,
                            })}
                            type="text"
                            as="textarea"
                            rows={4}
                            name={`str_description${current}`}
                            placeholder="Description"
                            value={
                                product.settings[`str_description${current}`]
                            }
                            onChange={handleChangeSetting}
                        />
                    ))}
                    <Form.Text className="text-muted">
                        Description {language ? `(${language})` : null}
                    </Form.Text>
                </Form.Group>
            </Row>

            <Button type="submit" variant="primary">
                Submit
            </Button>
        </Form>
    );
};

export default ProductEditForm;
