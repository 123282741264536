import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import getBonusAmount from '../helpers/getBonusAmount';
import _ from 'lodash';

const VAT_OPTIONS = [
    { name: '0%', value: '0' },
    { name: '10%', value: '10' },
    { name: '14%', value: '14' },
    { name: '24%', value: '24' },
];

const BONUS_TYPE_OPTIONS = [
    { name: 'No bonus', value: '' },
    { name: 'Percentage', value: 'percentage' },
    { name: 'Fixed', value: 'fixed' },
];

const normalizeValues = (obj = {}, precision = 2) => {
    let vals = _(obj).omitBy(_.isNil).omitBy(_.isNaN).value();
    Object.keys(vals).map((k) => {
        vals[k] = _.round(parseFloat(vals[k]), precision);
    });
    return vals;
};

const AmountInput = ({ settings, setSettings = () => {} }) => {
    useEffect(() => {
        if (settings.amount && settings.amount_vat && !netValue) {
            try {
                const amount = parseFloat(settings.amount);
                const vat = parseFloat(settings?.int_vat) / 100;

                const newNetValue = parseFloat(
                    vat ? amount / (1 + vat) : 0.0
                ).toFixed(2);

                if (newNetValue && !isNaN(newNetValue)) {
                    setNetValue(newNetValue);
                }
            } catch (error) {
                console.log('error settings net_value: ', error);
            }
        }
    }, [settings]);

    const [netValue, setNetValue] = useState(0);

    const handleChangeSetting = (e) => {
        setSettings({
            ...settings,
            [e.target.name]: e.target.value,
        });
    };

    const COMMON_PROPS = {
        as: Col,
        xs: 12,
        sm: 6,
    };

    const FORM_GROUP_PROPS_SMALL = {
        ...COMMON_PROPS,
        md: 2,
    };

    const FORM_GROUP_PROPS_BIG = {
        ...COMMON_PROPS,
        md: 4,
    };

    const handleChangeAmount = (e) => {
        const amount = e.target.value;
        const fieldName = e.target.name;

        const vat = parseFloat(settings?.int_vat) / 100;
        const amountFloat = parseFloat(amount);

        const netValueFloat = vat ? amountFloat / (1 + vat) : 0.0;
        const vatAmount = amountFloat - netValueFloat;

        const bonusType = settings?.str_bonus_type;
        const bonusValue = parseFloat(settings?.int_bonus_value);
        const bonusAmount = getBonusAmount(amountFloat, {
            bonusType,
            bonusValue,
        });

        let normalizedNetValue = parseFloat(netValueFloat);
        if (!isNaN(normalizedNetValue)) {
            setNetValue(_.round(normalizedNetValue, 2));
        }

        let newSettings = {};
        newSettings.amount_vat = vatAmount;
        newSettings.int_bonus_amount = bonusAmount;

        newSettings = normalizeValues(newSettings);

        setSettings({
            ...settings,
            ...newSettings,
            [fieldName]: amount,
        });
    };

    const handleChangeVat = (e) => {
        const newVat = e.target.value;
        const fieldName = e.target.name;

        const amountFloat = parseFloat(settings.amount);
        const vat = parseFloat(newVat) / 100;

        const netValueFloat = amountFloat / (1 + vat);
        const vatAmount = amountFloat - netValueFloat;

        const bonusValue = parseFloat(settings?.int_bonus_value);
        const bonusType = settings?.str_bonus_type;
        const bonusAmount = getBonusAmount(amountFloat, {
            bonusType,
            bonusValue,
        });

        let normalizedNetValue = parseFloat(netValueFloat);
        if (!isNaN(normalizedNetValue)) {
            setNetValue(_.round(normalizedNetValue, 2));
        }

        let newSettings = {};
        newSettings.amount = amountFloat;
        newSettings.amount_vat = vatAmount;
        newSettings.int_bonus_amount = bonusAmount;

        newSettings = normalizeValues(newSettings);

        setSettings({
            ...settings,
            ...newSettings,
            [fieldName]: newVat,
        });
    };

    const handleChangeNetValue = (e) => {
        const newNetValue = e.target.value;

        const netValueFloat = parseFloat(newNetValue);
        const vat = parseFloat(settings?.int_vat);
        // const amountFloat = netValueFloat * (1 + vat);
        const amountFloat = 100 * netValueFloat / (100 - vat);


        const vatAmount = amountFloat - netValueFloat;

        const bonusValue = parseFloat(settings?.int_bonus_value);
        const bonusType = settings?.str_bonus_type;
        const bonusAmount = getBonusAmount(amountFloat, {
            bonusType,
            bonusValue,
        });

        let newSettings = {};
        newSettings.amount = amountFloat;
        newSettings.amount_vat = vatAmount;
        newSettings.int_bonus_amount = bonusAmount;

        newSettings = normalizeValues(newSettings);

        setSettings({
            ...settings,
            ...newSettings,
        });

        setNetValue(newNetValue);
    };

    const handleChangeBonusType = (e) => {
        const newBonusType = e.target.value;

        const amountFloat = parseFloat(settings.amount);

        const bonusValue = parseFloat(settings.int_bonus_value);
        const bonusType = newBonusType;
        const bonusAmount = getBonusAmount(amountFloat, {
            bonusType,
            bonusValue,
        });

        let newSettings = {};
        newSettings.int_bonus_amount = bonusAmount;
        newSettings.int_bonus_value = bonusValue;

        newSettings = normalizeValues(newSettings);

        setSettings({
            ...settings,
            ...newSettings,
            str_bonus_type: newBonusType,
        });
    };

    const handleChangeBonusValue = (e) => {
        const newBonusValue = e.target.value;

        const bonusValueFloat = parseFloat(newBonusValue);
        const amountFloat = parseFloat(settings.amount);
        const bonusType = settings.str_bonus_type;
        const bonusAmount = getBonusAmount(amountFloat, {
            bonusType,
            bonusValue: bonusValueFloat,
        });

        let newSettings = {};
        newSettings.int_bonus_amount = bonusAmount;

        newSettings = normalizeValues(newSettings);

        setSettings({
            ...settings,
            ...newSettings,
            int_bonus_value: newBonusValue,
        });
    };

    return (
        <Container className="border rounded p-3 my-3">
            <Row className="mb-3">
                <Form.Group
                    {...FORM_GROUP_PROPS_BIG}
                    controlId="formGridAmount"
                >
                    <Form.Label>Amount</Form.Label>
                    <Form.Control
                        type="number"
                        name="amount"
                        placeholder="399.99"
                        min={0}
                        max={100000}
                        step={0.01}
                        value={settings?.amount}
                        onChange={handleChangeAmount}
                    />
                </Form.Group>

                <Form.Group
                    {...FORM_GROUP_PROPS_BIG}
                    controlId="formGridNetValue"
                >
                    <Form.Label>Net value</Form.Label>
                    <Form.Control
                        type="number"
                        name="net_value"
                        placeholder="5.75"
                        min={0}
                        max={100000}
                        step={0.01}
                        value={netValue}
                        onChange={handleChangeNetValue}
                    />
                </Form.Group>

                <Form.Group
                    {...FORM_GROUP_PROPS_SMALL}
                    controlId="formGridAmountVat"
                >
                    <Form.Label>VAT amount</Form.Label>
                    <Form.Control
                        type="number"
                        name="amount_vat"
                        placeholder="5.75"
                        min={0}
                        max={100000}
                        step={0.01}
                        value={settings.amount_vat}
                        onChange={handleChangeSetting}
                    />
                </Form.Group>

                <Form.Group
                    {...FORM_GROUP_PROPS_SMALL}
                    controlId="formGridIntVat"
                >
                    <Form.Label>VAT</Form.Label>
                    <Form.Control
                        as={'select'}
                        aria-label="VAT"
                        name="int_vat"
                        value={`${settings.int_vat}`}
                        onChange={handleChangeVat}
                        className="custom-select"
                    >
                        <option value="">VAT %</option>
                        {VAT_OPTIONS.map((item, index) => (
                            <option
                                key={`${index}-${item?.value}-vat-option`}
                                value={item?.value}
                            >
                                {item?.name}
                            </option>
                        ))}
                    </Form.Control>
                </Form.Group>
            </Row>

            <Row className="mb-3">
                <Form.Group
                    {...FORM_GROUP_PROPS_BIG}
                    controlId="formGridBonusAmount"
                >
                    <Form.Label>Bonus amount</Form.Label>
                    <Form.Control
                        type="number"
                        name="int_bonus_amount"
                        placeholder="3.15"
                        min={0}
                        max={100000}
                        step={0.01}
                        value={settings?.int_bonus_amount}
                        onChange={handleChangeSetting}
                        disabled={true}
                    />
                </Form.Group>

                <Form.Group
                    {...FORM_GROUP_PROPS_BIG}
                    controlId="formGridBonusType"
                >
                    <Form.Label>Bonus type</Form.Label>
                    <Form.Control
                        as={'select'}
                        aria-label="Bonus type"
                        name="str_bonus_type"
                        value={settings.str_bonus_type}
                        onChange={handleChangeBonusType}
                        className="custom-select"
                    >
                        <option value="">Type</option>
                        {BONUS_TYPE_OPTIONS.map((item, index) => (
                            <option
                                key={`${index}-${item?.value}-bonus-type-option`}
                                value={item?.value}
                            >
                                {item?.name}
                            </option>
                        ))}
                    </Form.Control>
                </Form.Group>

                <Form.Group
                    {...FORM_GROUP_PROPS_BIG}
                    controlId="formGridBonusAmount"
                >
                    <Form.Label>Bonus value</Form.Label>
                    <Form.Control
                        type="number"
                        name="int_bonus_value"
                        placeholder="3.5"
                        min={0}
                        max={100000}
                        step={0.01}
                        value={settings?.int_bonus_value}
                        onChange={handleChangeBonusValue}
                    />
                </Form.Group>
            </Row>
        </Container>
    );
};

export default AmountInput;
