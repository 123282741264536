import { useContext, useEffect, useState } from 'react';
import { Form, Button, InputGroup } from 'react-bootstrap';
import { NotificationManager } from 'react-notifications';
import Map from '../Map';
import AddressTableModal from './AddressTableModal';
import { GOOGLE_API_KEY } from './../../config';
import _ from 'lodash';
import getWarehousesList from '../../api/utils/warehouse/getWarehousesList';
import UserInfoContext from '../../contexts/UserInfoContext';
import getAdminShop from '../../api/utils/shop/getAdminShop';
import cn from 'classnames';

const BASE_GEO_CODE_URL = `https://maps.googleapis.com/maps/api/geocode/json?key=${GOOGLE_API_KEY}`;

const INITIAL_SHOP = {
    id: null,
    active: true,
    merchant: '',
    name: '',
    location: [0.0, 0.0],
    token: '',
    settings: {
        nonworking_days: [],
        open_time: [],
        public_key: '',
        private_key: '',
    },
};

const ShopInfoForm = ({
    id = null,
    onSubmit = () => { },
    submitLoading = false,
    shopInfo = null,
}) => {
    const {
        app: { id: appId },
        merchant: { id: merchantId },
    } = useContext(UserInfoContext);

    const [pos, setShop] = useState(INITIAL_SHOP);
    const [loading, setLoading] = useState(false);

    const [warehouses, setWarehouses] = useState([]);
    const [selectedWarehouses, setSelectedWarehouses] = useState([]);
    const [coordinatesInput, setCoordinatesInput] = useState(INITIAL_SHOP.location);
    const [coordUpdated, setCoordUpdated] = useState(false);

    const [addressInput, setAddressInput] = useState('');
    const [addressUpdated, setAddressUpdated] = useState(false);

    // Address fields
    const [showAddressListModal, setShowAddressListModal] = useState(false);
    const handleShowAddressListModal = () => setShowAddressListModal(true);
    const handleCloseAddressListModal = () => setShowAddressListModal(false);
    const [addressList, setAddressList] = useState();

    useEffect(() => {
        if (id) fetchShop(id);
    }, [id]);

    useEffect(() => {
        if (appId && merchantId) fetchWarehousesList();
    }, [appId, merchantId]);

    useEffect(() => {
        if (shopInfo) {
            setShop(shopInfo);
            setCoordinatesInput(shopInfo.location);
            setAddressInput(shopInfo.settings?.str_address || '');
        }
    }, [shopInfo]);

    const handleGoogleRequest = async (adr, flag = 'address') => {
        const requestOptions = {
            method: 'POST',
        };
        const response = await fetch(adr, requestOptions);
        const result = await response.json();

        if (result.status === 'OK' && result.results.length > 0) {
            if (result.results.length > 1) {
                setAddressList(result.results);
                handleShowAddressListModal();
            } else {
                const newShop = _.cloneDeep(pos);

                const coordinates = [
                    result.results[0].geometry.location.lat,
                    result.results[0].geometry.location.lng,
                ];

                const address = result.results[0].formatted_address;

                newShop.location = coordinates;
                newShop.settings.str_address = address;

                setCoordinatesInput(coordinates);
                setAddressInput(address);

                setShop(newShop);
            }
        } else {
            const newShop = _.cloneDeep(pos);
            newShop.settings.str_address = '';
            newShop.location = INITIAL_SHOP.location;

            setShop(newShop);
            NotificationManager.warning(
                result.error_message || 'Geocode was not successful',
                'Geocode error',
                5000
            );
        }
    };

    const handleAddressChanged = async (address) => {
        setAddressUpdated(false);

        const newShop = _.cloneDeep(pos);
        newShop.settings.str_address = address;
        setShop(newShop);

        const adr = `${BASE_GEO_CODE_URL}&address="${address}"`;
        await handleGoogleRequest(adr, 'address');
    };

    const handleCoordinatesChanged = async (coordinates) => {
        setCoordUpdated(false);

        const adr = `${BASE_GEO_CODE_URL}&latlng=${coordinates[0]},${coordinates[1]}`;

        await handleGoogleRequest(adr, 'coordinates');
    };

    const handleChangeShop = (e) => {
        const fieldName = e.target.name;
        const value = e.target.value;
        let newShop = _.cloneDeep(pos);
        _.set(newShop, fieldName, value);
        setShop(newShop);
    };

    const fetchShop = async (id) => {
        const resultHandler = (data) => {
            if (data?.status === 'accept' && data?.pos) {
                setShop(data.pos);
                setCoordinatesInput(data.pos.location);
                setAddressInput(data.pos.settings?.str_address || '');
                setLoading(false);
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
            setLoading(false);
        };

        try {
            setLoading(true);
            const res = await getAdminShop({ id }, errorHandler);
            resultHandler(res);
        } catch (error) {
            console.log('error fetching shop: ', error);
        }
    };

    const fetchWarehousesList = async (page = 1) => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                setWarehouses(data.warehouses);
            } else {
                setWarehouses([]);
            }
        };

        const errorHandler = (error) => {
            setWarehouses([]);
        };

        try {
            const result = await getWarehousesList(
                {
                    query_application: appId,
                    query_merchant: merchantId,
                    query_skip: 0,
                    query_count: 10000,
                },
                errorHandler
            );
            resultHandler(result);
        } catch (error) {
            errorHandler(error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const submitData = _.cloneDeep(pos);
        submitData.name = pos.name;
        submitData.location = pos.location;
        submitData.token = pos.token;
        submitData.public_key = pos.settings.public_key
        submitData.private_key = pos.settings.private_key



        if (!id) {
            submitData.application = appId;
        }

        const updatedShop = await onSubmit(submitData);

        if (!_.isEmpty(updatedShop)) {
            setShop(updatedShop);
            setCoordinatesInput(updatedShop.location);
            setAddressInput(updatedShop.settings?.str_address || '');
        }
    };

    function onAddressSelected(address) {
        handleCloseAddressListModal();
        const coordinates = [
            address.geometry.location.lat,
            address.geometry.location.lng,
        ];
        const submitAddress = address.formatted_address;

        const submitData = _.cloneDeep(pos);
        submitData.location = coordinates;
        submitData.settings.str_address = submitAddress;

        setCoordinatesInput(coordinates);
        setAddressInput(submitAddress);

        setShop(submitData);
    }

    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="data.name">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder=""
                        name="name"
                        value={pos?.name || ''}
                        onChange={handleChangeShop}
                    />
                    <Form.Text className="text-muted">
                        Name of the shop
                    </Form.Text>
                </Form.Group>

                <Form.Group className="mb-3" controlId="data.token">
                    <Form.Label>Token</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder=""
                        name="token"
                        value={pos?.token || ''}
                        onChange={handleChangeShop}
                    />

                </Form.Group>
                <Form.Group className="mb-3" controlId="data.settings.public_key">
                    <Form.Label>Public Key</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder=""
                        name="settings.public_key"
                        value={pos?.settings?.public_key || ''}
                        onChange={handleChangeShop}
                    />

                </Form.Group>

                <Form.Group className="mb-3" controlId="data.settings.private_key">
                    <Form.Label>Private Key</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder=""
                        name="settings.private_key"
                        value={pos?.settings?.private_key || ''}
                        onChange={handleChangeShop}
                    />

                </Form.Group>
                <InputGroup className="mb-3">
                    <InputGroup.Text>Search by address</InputGroup.Text>
                    <Form.Control
                        type="text"
                        placeholder="Address"
                        name="settings.str_address"
                        value={addressInput}
                        onChange={(e) => {
                            setAddressInput(e.target.value);
                            setAddressUpdated(true);
                        }}
                    />

                    <Button
                        variant={addressUpdated ? 'danger' : 'outline-secondary'}
                        id="find-coordinates"
                        type="button"
                        onClick={(e) =>
                            handleAddressChanged(
                                addressInput || INITIAL_SHOP.settings.str_address
                            )
                        }
                    >
                        Find
                    </Button>
                </InputGroup>

                <InputGroup className="mb-3">
                    <InputGroup.Text>Search by coordinates</InputGroup.Text>
                    <Form.Control
                        aria-label="Latitude"
                        type="text"
                        placeholder="60.xxxx"
                        name="location.0"
                        value={coordinatesInput?.[0]}
                        onChange={(e) => {
                            setCoordinatesInput((prev) => [
                                e.target.value,
                                prev?.[1],
                            ]);
                            setCoordUpdated(true);
                        }}
                    />
                    <Form.Control
                        aria-label="Longitude"
                        type="text"
                        placeholder="24.xxxx"
                        name="location.1"
                        value={coordinatesInput?.[1]}
                        onChange={(e) => {
                            setCoordinatesInput((prev) => [
                                prev?.[0],
                                e.target.value,
                            ]);
                            setCoordUpdated(true);
                        }}
                    />

                    <Button
                        variant={coordUpdated ? 'danger' : 'outline-secondary'}
                        id="find-coordinates"
                        type="button"
                        onClick={(e) =>
                            handleCoordinatesChanged(
                                coordinatesInput || INITIAL_SHOP.location
                            )
                        }
                    >
                        Find
                    </Button>
                </InputGroup>

                <div>
                    <h4>Location</h4>
                    {pos.location ?? null ? (
                        <Map
                            latitude={pos.location[0]}
                            longitude={pos.location[1]}
                        />
                    ) : (
                        <div className="text-muted">'no location provided'</div>
                    )}
                </div>

                <Button
                    variant="primary"
                    type="submit"
                    className={cn('mt-3')}
                    disabled={submitLoading}
                >
                    Submit
                </Button>
            </Form>

            <AddressTableModal
                addressList={addressList}
                show={showAddressListModal}
                onAddressSelected={onAddressSelected}
                onHide={handleCloseAddressListModal}
            ></AddressTableModal>
        </div>
    );
};

export default ShopInfoForm;