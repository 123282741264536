import { useEffect, useState } from 'react';
import { timesObject, marksObject, maxTimeIntervals } from './helpers/timeHelper';
import CheckboxSwitch from './../../Form/elements/CheckboxSwitch';
import { Slider } from '@mui/material';

const OpenDaysElement = ({ defaValues, dayIndex, dayName, defaActive, onChange }) => {
    const marks1 = marksObject();
    const marks = timesObject();

    const getIndexByTime = (value) => marks.findIndex((mark) => mark.time === value);
    const getIndexByValue = (value) => marks.findIndex((mark) => mark.value === Math.round(value * maxTimeIntervals / 100));

    const initialIndex1 = getIndexByTime(defaValues[0]) ?? 0;
    const initialIndex2 = getIndexByTime(defaValues[1]) ?? marks.length - 1;

    const [intervalLabel1, setIntervalLabel1] = useState(marks[initialIndex1]?.label || '00:00');
    const [intervalLabel2, setIntervalLabel2] = useState(marks[initialIndex2]?.label || '23:59');
    const [isActive, setIsActive] = useState(defaActive);
    const [interval1, setInterval1] = useState(marks1[initialIndex1]?.value || 0);
    const [interval2, setInterval2] = useState(marks1[initialIndex2]?.value || 100);

    useEffect(() => {
        onChange(dayName, dayIndex, isActive, marks[initialIndex1]?.time, marks[initialIndex2]?.time);
    }, [isActive, interval1, interval2]);

    const onSlide = (event, newValue) => {
        const index1 = getIndexByValue(newValue[0]);
        const index2 = getIndexByValue(newValue[1]);

        if (index1 !== -1 && index2 !== -1) {
            setIntervalLabel1(marks[index1].label);
            setIntervalLabel2(marks[index2].label);
            setInterval1(marks1[index1].value);
            setInterval2(marks1[index2].value);
            onChange(dayName, dayIndex, isActive, marks[index1].time, marks[index2].time);
        }
    };

    const onActivate = (event) => {
        setIsActive(event.target.checked)
    };

    return (
        <div className="row mb-3">
            <div className="col-md-1">
                <label htmlFor={dayName}>{dayName}</label>
            </div>
            <div className="col-md-1">
                <CheckboxSwitch value={isActive} onChange={onActivate} name={dayName} />
            </div>
            <div className="col-md-6">
                <Slider
                    name={dayName}
                    value={[interval1, interval2]}
                    marks={marks1}
                    disabled={!isActive}
                    onChange={onSlide}
                    step={100 / maxTimeIntervals}
                />
            </div>
            <div className="col-md-4">{`${intervalLabel1} - ${intervalLabel2}`}</div>
        </div>
    );
};

export default OpenDaysElement;
