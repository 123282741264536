import { useEffect, useState } from 'react';
import {
  NotificationManager,
  NotificationContainer,
} from 'react-notifications';
import useSearchString from './../../../utils/useSearchString';
import SmartForm from './../../Form/SmartForm';
import { getDefaultOpenTimes, days } from './helpers/timeHelper';
import ButtonActionForm from './../../Form/elements/ButtonActionForm';
import OpenDaysElement from './OpenDaysElement';
import getAdminShop from './../../../api/utils/shop/getAdminShop';
import prepareToPublish from './../helpers/prepareToPublish';
import { adminUpdateShop } from '../../../api/utils/shop/setAdminShop';
import useLoader from './../../UI/helpers/useLoader';
import LoadingBar from './../../UI/LoadingBar';

const ShopOpenTime = () => {
  const [shopInfo, setShopInfo] = useState(null);
  const [currentOpenTimes, setCurrentOpenTimes] = useState([]);
  const [progress, done] = useLoader([shopInfo]);
  const { shop = null } = useSearchString();

  useEffect(() => {
    let isMounted = true;

    const fetchShopInfo = async () => {
      try {
        const data = await getAdminShop({ id: shop });
        if (isMounted && data?.status === 'accept' && data?.pos) {
          setShopInfo(data.pos);

          const openTimes = data.pos.settings?.open_time ?? getDefaultOpenTimes();
          setCurrentOpenTimes(openTimes);
        } else {
          NotificationManager.error('Error fetching shop data');
        }
      } catch (error) {
        NotificationManager.error(error.description, 'Error', 4000);
      }
    };

    if (shop) fetchShopInfo();

    return () => {
      isMounted = false;
    };
  }, [shop]);

  const saveShop = async () => {
    try {
      const settings = { open_time: currentOpenTimes, nonworking_days: shopInfo?.settings?.nonworking_days || [], };
      const payload = prepareToPublish(shopInfo.merchant, shop, { settings });

      const response = await adminUpdateShop(payload);
      if (response?.status === 'accept') {
        NotificationManager.success('Saved successfully');
      } else {
        NotificationManager.error('Error saving shop data');
      }
    } catch (error) {
      NotificationManager.error(error.description, 'Error', 4000);
    }
  };

  const onChange = (dayName, dayIndex, isActive, interval1, interval2) => {
    const updatedTimes = [...currentOpenTimes];
    updatedTimes[dayIndex] = {
      day: dayName,
      is_active: isActive,
      interval: [interval1, interval2],
    };
    setCurrentOpenTimes(updatedTimes);
  };

  if (!done) return <LoadingBar progress={progress} />;
  if (!shopInfo) return <p>Shop not found</p>;

  return (
    <div>
      <h4>
        Point of Sales: {shopInfo.name}
      </h4>
      <NotificationContainer />
      <label className="mb-3">Opening Hours</label>
      <SmartForm onSubmit={saveShop}>
        <div className="col">
          {days.map((day, index) => (
            <OpenDaysElement
              key={day}
              dayIndex={index}
              dayName={day}
              defaActive={currentOpenTimes[index]?.is_active || false}
              defaValues={currentOpenTimes[index]?.interval || ['00:00', '23:59']}
              onChange={onChange}
            />
          ))}
        </div>
        <ButtonActionForm label="Save changes" name="active" value={true} type="submit" />
      </SmartForm>
    </div>
  );
};

export default ShopOpenTime;
