import { useEffect, useState } from 'react';
import {
  NotificationManager,
  NotificationContainer,
} from 'react-notifications';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import useSearchString from './../../../utils/useSearchString';
import getAdminShop from './../../../api/utils/shop/getAdminShop';
import prepareToPublish from './../helpers/prepareToPublish';
import { adminUpdateShop } from '../../../api/utils/shop/setAdminShop';
import useLoader from './../../UI/helpers/useLoader';
import LoadingBar from './../../UI/LoadingBar';
import MultySelectYearCalendar from './MultySelectYearCalendar';
import moment from 'moment';

const ShopCloseDays = () => {
  const history = useHistory();

  const [shopInfo, setShopInfo] = useState(null);
  const [progress, done] = useLoader([shopInfo]);
  const { shop = null } = useSearchString();

  const [currentCloseDays, setCurrentCloseDays] = useState([]);

  useEffect(() => {
    let isMounted = true;
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    const resultHandler = (data) => {
      if (data?.status === 'accept' && data?.pos) {
        setShopInfo(data.pos);
      } else {
        NotificationManager.error('Error fetching shop data');
      }
    };

    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
    };

    getAdminShop({ id: shop }, errorHandler).then(resultHandler);
  }, [shop]);

  useEffect(() => {
    if (shopInfo) {
      const closeDays = shopInfo.settings?.nonworking_days || [];
      setCurrentCloseDays(
        closeDays.map((e, i) => ({
          id: i,
          startDate: moment(e).toDate(),
          endDate: moment(e).toDate(),
          color: 'pink',
        }))
      );
    }
  }, [shopInfo]);

  const saveShop = (data) => {
    const resultHandler = (data) => {
      if (data && data.status === 'accept') {
        NotificationManager.success('Saved successfully');
      } else {
        NotificationManager.error('Error saving data');
      }
    };
    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
    };

    const openTime = shopInfo?.settings?.open_time ?? [];
    const settings = {
      nonworking_days: data,
      open_time: openTime

    }

    adminUpdateShop(
      { ...prepareToPublish(shopInfo.merchant, shop, { settings }) },
      errorHandler
    ).then(resultHandler);
  };

  const onSubmit = (data) => {
    const dataToSave = data.map((e) => moment(e.startDate).format('YYYY-MM-DD'));
    saveShop(dataToSave);
  };

  if (done && shop && shopInfo) {
    return (
      <div>
        <h4>
          Point of sales {shopInfo.name}
        </h4>
        <NotificationContainer />
        <label className="mb-3">Close days</label>
        <MultySelectYearCalendar
          onSubmit={onSubmit}
          closeDaysEvents={currentCloseDays}
        />
      </div>
    );
  } else {
    return !done ? <LoadingBar progress={progress} /> : 'Shop not found';
  }
};

export default ShopCloseDays;