import Honkio from '../../Honkio';

const adminShopSet = async function (options, errorHandler) {
  return Honkio().mainShop.userFetch(
    'posset',
    {
      ...options,
      version: 3,
    },
    errorHandler
  );
};

const adminUpdateShop = (shop, errorHandler) => {
  return adminShopSet(
    {
      ...shop,
      action: 'update',
      command: 'posset'
    },
    errorHandler
  );
};

const adminCreateShop = (shop, errorHandler) => {
  return adminShopSet(
    {
      ...shop,
      action: 'create',
      command: 'posset'
    },
    errorHandler
  );
};

const adminDeleteShop = (id, errorHandler) => {
  return adminShopSet(
    {
      id,
      action: 'delete',
    },
    errorHandler
  );
};

export { adminDeleteShop, adminCreateShop, adminUpdateShop };
