import { useEffect, useState, useMemo, useContext } from 'react';
import useSearchString from '../../utils/useSearchString';
import ShopList from './ShopList';
import InlineSearch from '../UI/InlineSearch';
import searchFilterIfConsist from '../UI/helpers/searchFilterIfConsist';
import typifyIfEquals from '../UI/helpers/typifyIfEquals';
import UserInfoContext from '../../contexts/UserInfoContext';
import { NotificationManager, NotificationContainer } from 'react-notifications';
import { Link } from 'react-router-dom';
import getAdminShopList from './../../api/utils/shop/getAdminShopList';
import DeleteConfirmModel from '../Model/DeleteConfirmModel';
import { adminDeleteShop } from '../../api/utils/shop/setAdminShop';
import { active } from 'd3';

const colsToSearch = ['name'];

const Shops = () => {
    const [shopsList, setShopsList] = useState([]);
    const [keyword, setKeyword] = useState('');
    const [type, setType] = useState('');
    const [perPage, setPerPage] = useState(50);
    const [totalRows, setTotalRows] = useState(150);
    const [loading, setLoading] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [deleteId, setDeleteId] = useState();
    const userInfo = useContext(UserInfoContext);
    const { merchant = null } = useSearchString();
    const merchantId = merchant || userInfo.merchant?.id || null;
    const appid = userInfo?.app?.id;

    useEffect(() => {
        if (merchantId) {
            const delaySearch = setTimeout(() => {
                fetchShops(1, keyword);
            }, 300); // Add debounce of 300ms
            return () => clearTimeout(delaySearch);
        }
    }, [merchantId, keyword]);

    const fetchShops = async (page) => {
        setLoading(true);
        try {
            const data = await getAdminShopList({
                page: page - 1,
                query_count: perPage,
                appid,
                merchant: merchantId,
                query_search: keyword,


            });
            if (data && data.status === 'accept') {
                setShopsList(
                    data.pos?.map(pos => ({
                        id: pos._id,
                        application: pos.application,
                        merchant: pos.merchant,
                        name: pos.name,
                        pos_identifier: pos.pos_identifier,
                        token: pos.token,
                        location: pos.location,
                        active: pos.active
                    })) ?? []
                );
                setTotalRows(data.total);
            } else {
                NotificationManager.error('Error fetching shops');
            }
        } catch (error) {
            NotificationManager.error(error.description || 'Error occurred');
        } finally {
            setLoading(false);
        }
    };

    const displayedShops = useMemo(() => {
        const filtered = searchFilterIfConsist(shopsList, colsToSearch, keyword);
        return typifyIfEquals(filtered, type);
    }, [shopsList, keyword, type]);

    return (
        <div className="container-fluid">
            <NotificationContainer />
            <h2>Point of sales</h2>
            {merchantId ? (
                <>
                    <Link
                        to={`/shop/create?appid=${appid}&merchant=${merchantId}`}
                        className="btn btn-outline-primary mt-1 mb-3"
                    >
                        Create a point of sales
                    </Link>
                    <InlineSearch setKeyword={setKeyword} />
                    <ShopList
                        data={displayedShops}
                        rowsCountPerPage={perPage}
                        totalRows={totalRows}
                        loading={loading}
                        onSaveEdit={fetchShops}
                    />
                </>
            ) : (
                'Select merchant first'
            )}
            <DeleteConfirmModel
                modal={showConfirmModal}
                onModalClose={() => setShowConfirmModal(false)}
                handleCloseConfirmDelete={() => fetchShops(1)}
            />
        </div>
    );
};

export default Shops;
