import Honkio from '../../Honkio';

const getUpdateShopList = (options = {}, errorHandler = {}) => {
    const { merchant: query_merchant, name, id, active } = options;
    return Honkio().mainShop.userFetch(
        'posset',
        {
            query_merchant,
            command: 'posset',
            version: 3,
            action: 'update',
            name,
            id,
            active
        },
        errorHandler
    );
};

export default getUpdateShopList;
