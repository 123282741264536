import Honkio from '../../Honkio';

const getAdminShop = (options = {}, errorHandler = {}) => {
  const { id } = options;
  return Honkio().mainShop.userFetch(
    'posget',
    {
      id,
      version: 3,
      action: 'read',
    },
    errorHandler
  );
};

export default getAdminShop;
