import { useEffect, useState } from 'react';
import Calendar from 'rc-year-calendar';

const MultySelectYearCalendar = ({ closeDaysEvents, onSubmit = () => { } }) => {
    const [currentEvent, setCurrentEvent] = useState(null);
    const [dataSource, setDataSource] = useState(closeDaysEvents);

    useEffect(() => {
        let isMounted = true;
        return () => {
            isMounted = false;
        };
    }, []);

    useEffect(() => {
        if (currentEvent) {
            if (currentEvent.id === undefined) {
                const newEvent = {
                    id: dataSource.length > 0 ? Math.max(...dataSource.map(evt => evt.id)) + 1 : 0,
                    startDate: currentEvent.startDate,
                    endDate: currentEvent.endDate,
                    color: 'pink',
                };
                setDataSource([...dataSource, newEvent]);
            } else {
                setDataSource(dataSource.filter(item => item.id !== currentEvent.id));
            }
            setCurrentEvent(null);
        }
    }, [currentEvent]);

    const onDayClick = (event) => {
        if (event.events?.length > 0) {
            setCurrentEvent({
                id: event.events[0].id,
                startDate: event.date,
                endDate: event.date,
            });
        } else {
            setCurrentEvent({ startDate: event.date, endDate: event.date });
        }
    };

    return (
        <div>
            <Calendar
                dataSource={dataSource}
                enableRangeSelection={false}
                onDayClick={onDayClick}
                style="background"
            />
            <button className='btn btn-primary btn-lg btn-block' onClick={() => onSubmit(dataSource)}>
                Save changes
            </button>
        </div>
    );
};

export default MultySelectYearCalendar;
