import { useState } from 'react';
import useSearchString from '../../utils/useSearchString';
import DataTable from 'react-data-table-component';
import Wait from '../UI/Wait';
import Icon from '../UI/Icon';
import { Modal, Button, Form } from 'react-bootstrap';
import getUpdateShopList from './../../api/utils/shop/UpdateAdminShop';
import getdeleteShopList from './../../api/utils/shop/DeleteAdminShop';
import { NotificationManager } from 'react-notifications';
import { Link } from 'react-router-dom';

const ShopList = ({
    data,
    rowsCountPerPage,
    totalRows,
    handlePerRowsChange,
    handlePageChange,
    loading,
    onSaveEdit
}) => {
    const { appid } = useSearchString();
    const [editData, setEditData] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [loadingDelete, setLoadingDelete] = useState(false);


    const handleEditClick = (row) => {
        setEditData({
            id: row.id,
            name: row.name,
            pos_identifier: row.pos_identifier,
            active: row.active ?? false,
        });
        setShowModal(true);
    };

    const handleSave = (updatedData) => {
        if (!updatedData?.name || !updatedData?.id) {
            console.log('Name and ID are required.');
            return;
        }

        setLoadingSave(true);

        getUpdateShopList({
            merchant: appid,
            query_count: rowsCountPerPage,
            page: 0,
            name: updatedData?.name,
            id: updatedData?.id,
            active: updatedData?.active,
        })
            .then((data) => {
                if (data && data.status === 'accept') {

                    onSaveEdit(1);
                } else {
                    console.log('Failed to update shop');
                }
            })
            .catch((error) => {
                console.error('Error during update:', error);
                console.log('An error occurred while updating shop');
            })
            .finally(() => {
                setLoadingSave(false);
                setShowModal(false);
                setEditData(null);
            });
    };


    const handleDeleteClick = (id) => {
        setDeleteId(id);
        setShowDeleteModal(true);
    };

    const handleConfirmDelete = () => {
        if (!deleteId) return;

        setLoadingDelete(true);

        getdeleteShopList({
            merchant: appid,
            id: deleteId,
        })
            .then((data) => {
                if (data && data.status === 'accept') {
                    NotificationManager.success(
                        'Successfully deleted',
                        'Success',
                        4000
                    );
                    onSaveEdit(1);
                }
            })
            .catch((error) => {
                console.error('Error during deletion:', error);
            })
            .finally(() => {
                setLoadingDelete(false);
                setShowDeleteModal(false);
                setDeleteId(null);
            });
    };


    const columns = [
        { name: '#', grow: 0, cell: (_, index) => index + 1 },
        { name: 'Name', sortable: true, selector: (row) => row.name, grow: 2 },

        {
            name: 'Active',
            grow: 0,
            cell: (row, index) => (
                <Form.Switch
                    id={`is-active-switch-${row?.id}-${index}`}
                    checked={!!row?.active}
                    className="m-0"
                    name="is_active"
                    label=""

                    onChange={() => {
                        handleSave({ ...row, active: !row.active })
                    }}
                />
            ),
        },

        {
            name: 'Actions',
            right: true,
            grow: 2,
            cell: (row) => (
                <div>
                    <Link to={`/shop/edit?merchant=${row.merchant}&shop=${row.id}`}>
                        <Icon iconName="btn bi-pen" />
                    </Link>
                    <Link to={`/shop/opentime?shop=${row.id}`}>
                        <Icon iconName="btn bi-clock" />
                    </Link>
                    <Link to={`/shop/closedays?shop=${row.id}`}>
                        <Icon iconName="bi bi-calendar4-week" />
                    </Link>
                    <span
                        onClick={() => handleDeleteClick(row.id)}
                        style={{ cursor: 'pointer', marginLeft: '10px' }}
                    >
                        <Icon iconName="bi bi-trash" />
                    </span>
                </div>
            ),
        },
    ];

    return (
        <>
            <DataTable
                dense
                striped
                highlightOnHover
                data={data}
                columns={columns}
                pagination
                paginationPerPage={rowsCountPerPage}
                paginationRowsPerPageOptions={[rowsCountPerPage]}
                paginationServer
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                paginationTotalRows={totalRows}
                progressPending={loading}
                progressComponent={<Wait />}
            />

            {/* Edit Modal */}

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <label className="form-label">Name:</label>
                    <input
                        className="form-control"
                        type="text"
                        value={editData?.name || ''}
                        onChange={(e) => setEditData({ ...editData, name: e.target.value })}
                    />

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)} disabled={loadingSave}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => handleSave(editData)} disabled={loadingSave}>
                        {loadingSave ? 'Saving...' : 'Save'}
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Delete Confirmation Modal */}
            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)} disabled={loadingDelete}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleConfirmDelete} disabled={loadingDelete}>
                        {loadingDelete ? 'Deleting...' : 'Delete'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ShopList;
