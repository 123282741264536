import Honkio from '../../Honkio';

const getdeleteShopList = (options = {}, errorHandler = {}) => {
    const { merchant: name, id } = options;
    return Honkio().mainShop.userFetch(
        'posset',
        {
            command: 'posdelete',
            version: 3,
            action: 'read',
            name,
            id
        },
        errorHandler
    );
};

export default getdeleteShopList;
