import React, { useContext, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { isNode, useStoreState } from 'react-flow-renderer';
import { NotificationManager } from 'react-notifications';
import Select from 'react-select';
import getAdminVelocityRulesList from '../../api/utils/velocityRule/getAdminVelocityRulesList';
import ConsumerInfoContext from '../../contexts/ConsumerInfoContext';
import ButtonActionForm from '../Form/elements/ButtonActionForm';
import SmartForm from '../Form/SmartForm';
import getFormDefaultValues from './helpers/getFormDefaultValues';
import getRfData from './helpers/getRFdata';

const getBooleanFromParameter = (value) => {
    let checked = false;
    if (value) {
        if (typeof value === 'boolean') {
            checked = value;
        } else {
            if (value.length > 0) {
                checked = value[0] === 'yes';
            }
        }
    }
    return checked;
};

const SelectedNode = ({
    state,
    nodes,
    edges,
    setNodes,
    setNodesWithSaving,
    products,
    messages,
    serviceModels,
    changeNodes,
    onDeleteNode,
    onResetNodes,
}) => {
    const [element, setElement] = useState(null);

    const [editProductsStatus, setEditProductsStatus] = useState(false);
    //const [editSendPush, setEditSendPush] = useState(false);
    const [editAmountStatus, setEditAmountStatus] = useState(false);

    const [reserveStatus, setReserveStatus] = useState(false);
    const [publicStatus, setPublicStatus] = useState(false);
    const [salesPointStatus, setSalesPointStatus] = useState(false);

    const [productList, setProductsList] = useState([]);

    const { is_superadmin } = useContext(ConsumerInfoContext);

    // Emails
    const [ownerMessage, setOwnerMessage] = useState();
    const [thirdPersonMessage, setThirdPersonMessage] = useState();
    // Push notifications
    const [ownerPush, setOwnerPush] = useState();
    const [thirdPersonPush, setThirdPersonPush] = useState();

    const [selectedServiceModels, setSelectedServiceModels] = useState([]);

    const [velocityRules, setVelocityRules] = useState([]);
    const [selectedVelocityRules, setSelectedVelocityRules] = useState(null);
    const [additionalReceiver, setAdditionalReceiver] = useState('');

    useEffect(() => {
        const [first] = state || [];
        first && isNode(first) && setElement(first);
    }, [state]);

    useEffect(() => {
        if (element && element.data && element.data.options) {
            setPublicStatus(
                getBooleanFromParameter(element.data.options['public'])
            );
            setReserveStatus(
                getBooleanFromParameter(element.data.options['reserve'])
            );
            setSalesPointStatus(
                getBooleanFromParameter(element.data.options['sales_point'])
            );
            setEditProductsStatus(
                getBooleanFromParameter(element.data.options['edit_products'])
            );
            setEditAmountStatus(
                getBooleanFromParameter(element.data.options['edit_amount'])
            );
            setProductsList(element.data.options['bundled_products']);
            setOwnerMessage(element.data.options.send_message?.owner);
            setThirdPersonMessage(
                element.data.options.send_message?.third_person
            );
            setAdditionalReceiver(
                element.data.options.send_message?.additional_receiver
            );
            setOwnerPush(element.data.options.send_push?.owner);
            setThirdPersonPush(element.data.options.send_push?.third_person);
            setSelectedServiceModels(element.data.options.service_models);
            setSelectedVelocityRules(element.data.options.velocity_rules);
        } else {
            setPublicStatus(false);
            setReserveStatus(false);
            setSalesPointStatus(false);
            setEditProductsStatus(false);
            setEditAmountStatus(false);
            setProductsList([]);
            setOwnerMessage(null);
            setThirdPersonMessage(null);
            setOwnerPush(null);
            setThirdPersonPush(null);
            setSelectedServiceModels([]);
            setSelectedVelocityRules([]);
        }
    }, [element]);

    const fetchVelocityRules = async () => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                setVelocityRules(data.velocity_rules);
            } else {
                setVelocityRules([]);
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
            setVelocityRules([]);
        };

        getAdminVelocityRulesList(
            {
                query_skip: 0,
                query_count: 10000, // TODO: Fix this
            },
            errorHandler
        ).then((data) => {
            return resultHandler(data);
        });
    };

    useEffect(() => {
        fetchVelocityRules();
    }, []);

    const onSubmit = (data) => {
        const { id } = element;
        const existingNode = nodes.find((el) => el.id === id);

        if (existingNode) {
            // Node changed
            const { x, y } = existingNode.__rf.position;
            const position = { x: Math.round(x), y: Math.round(y) };

            const newData = {
                label: element.id,
                edit_products: editProductsStatus,
                edit_amount: editAmountStatus,
                pb: publicStatus,
                reserve: reserveStatus,
                sales_point: salesPointStatus,
                bundled_products: productList,
                send_message: {
                    owner: ownerMessage,
                    third_person: thirdPersonMessage,
                    additional_receiver: additionalReceiver,
                },
                send_push: {
                    owner: ownerPush,
                    third_person: thirdPersonPush,
                },
                service_models: selectedServiceModels,
                velocity_rules: selectedVelocityRules,
            };

            let newElement = { ...element, ...getRfData(newData), position };
            let newNodes = nodes.map((el) => (el.id === id ? newElement : el));

            setNodes(newNodes);
            setElement(newElement);
        } else {
            // Filter out removed element
            let newNodes = nodes.filter((el) => el.id !== id);
            setNodes(newNodes);
            setElement(null);
        }
    };

    const productsToOptions = (what) => {
        if (what)
            return Object.keys(what).map((k) => {
                return { value: what[k].id, label: what[k].name };
            });
    };

    const productsToValues = (what) => {
        if (what) {
            return what.map((k) => {
                const prod = products.find((e) => e.id === k);
                if (prod) {
                    return { value: k, label: prod.name };
                }
            });
        } else {
            return null;
        }
    };

    const messagesToOptions = (what) => {
        if (what)
            return Object.keys(what).map((k) => {
                return { value: what[k]._id, label: what[k].name };
            });
    };

    const messageToValue = (messageId) => {
        if (messageId) {
            const mess = messages.find((e) => e._id === messageId);
            if (mess) {
                return { value: messageId, label: mess.name };
            }
        } else {
            return null;
        }
    };

    if (!element) return 'Select something';

    const modelsToOptions = (what) => {
        if (what)
            return Object.keys(what).map((k) => {
                return { value: what[k]?._id, label: what[k]?.name };
            });
    };

    const modelsToValues = (what) => {
        if (what) {
            return what.map((k) => {
                const prod = serviceModels
                    ? serviceModels.find((e) => e?._id === k)
                    : null;
                if (prod) {
                    return { value: k, label: prod?.name };
                }
            });
        } else {
            return null;
        }
    };

    const velocityRulesToOptions = (what) => {
        if (what)
            return Object.keys(what).map((k) => {
                return { value: what[k]?._id, label: what[k]?.name };
            });
    };

    const velocityRulesToValues = (what) => {
        if (what) {
            return what.map((k) => {
                const prod = velocityRules
                    ? velocityRules.find((e) => e?._id === k)
                    : null;
                if (prod) {
                    return { value: k, label: prod?.name };
                }
            });
        } else {
            return null;
        }
    };

    return (
        <SmartForm
            onSubmit={onSubmit}
            defaultValues={getFormDefaultValues(element)}
        >
            <div className="mt-3">
                <label>
                    id <input type="text" value={element.id} disabled={true} />
                </label>
            </div>
            <div>
                <div className="row">
                    <div className="col-md-6">
                        PRODUCTS
                        <hr />
                        <label></label>
                        <Select
                            isMulti
                            className="mb-3"
                            name="bundled_products"
                            label="Products"
                            options={productsToOptions(products)}
                            type="text"
                            value={productsToValues(productList)}
                            onChange={(e) => {
                                setProductsList(e.map((e) => e.value));
                            }}
                        />
                    </div>
                    <div className="col-md-6">
                        MESSAGES
                        <hr />
                        Message to order owner
                        <Select
                            className="mb-3"
                            name="owner_message"
                            label="Message to order owner"
                            options={messagesToOptions(messages)}
                            type="text"
                            value={messageToValue(ownerMessage)}
                            onChange={(e) => {
                                setOwnerMessage(e.value);
                            }}
                        />
                        Message to third Person
                        <Select
                            className="mb-3"
                            name="owner_message"
                            label="Message to third Person"
                            options={messagesToOptions(messages)}
                            type="text"
                            value={messageToValue(thirdPersonMessage)}
                            onChange={(e) => {
                                setThirdPersonMessage(e.value);
                            }}
                        />
                        Additional receiver
                        <Form.Control
                            name="additional_receiver"
                            className="mb-3"
                            type="email"
                            value={additionalReceiver}
                            onChange={(e) => {
                                setAdditionalReceiver(e.target.value);
                            }}
                            placeholder="abc@gmail.com"
                        />
                    </div>
                    <div className="col-md-6">
                        Push notifications
                        <hr />
                        Push to order owner
                        <Select
                            className="mb-3"
                            name="send_push.owner_push"
                            label="Push to order owner"
                            options={messagesToOptions(messages)}
                            type="text"
                            value={messageToValue(ownerPush)}
                            onChange={(e) => {
                                setOwnerPush(e.value);
                            }}
                        />
                        Push to third Person
                        <Select
                            className="mb-3"
                            name="send_push.third_person"
                            label="Push to third Person"
                            options={messagesToOptions(messages)}
                            type="text"
                            value={messageToValue(thirdPersonPush)}
                            onChange={(e) => {
                                setThirdPersonPush(e.value);
                            }}
                        />
                    </div>

                    {is_superadmin ? (
                        <div className="col-md-6">
                            SERVICE MODELS
                            <hr />
                            <label></label>
                            <Select
                                isMulti
                                className="mb-3"
                                name="service_models"
                                label="Service models"
                                options={modelsToOptions(serviceModels)}
                                type="text"
                                value={modelsToValues(selectedServiceModels)}
                                onChange={(e) => {
                                    setSelectedServiceModels(
                                        e.map((e) => e.value)
                                    );
                                }}
                            />
                        </div>
                    ) : null}

                    {element?.id === 'aml_authorization' ? (
                        <div className="col-md-6">
                            VELOCITY RULES
                            <hr />
                            <label></label>
                            <Select
                                isMulti
                                className="mb-3"
                                name="velocity_rules"
                                label="Velocity Rules"
                                options={velocityRulesToOptions(velocityRules)}
                                type="text"
                                value={velocityRulesToValues(
                                    selectedVelocityRules
                                )}
                                onChange={(e) =>
                                    setSelectedVelocityRules(
                                        e.map((e) => e.value)
                                    )
                                }
                            />
                        </div>
                    ) : (
                        <div className="col-md-6 text-muted">
                            Select "aml_authorization" status to add Velocity
                            rules (AML)
                            <hr />
                            <label></label>
                            <div>Not available for other status types</div>
                        </div>
                    )}
                </div>
                <div className="row">
                    <label>
                        <input
                            className="ml-3"
                            id={'public'}
                            name={'public'}
                            type="checkbox"
                            checked={publicStatus}
                            onChange={(e) => setPublicStatus(!publicStatus)}
                        />
                        {'public'}
                    </label>
                </div>
                {false && JSON.stringify(element?.data.options)}
            </div>
            <ButtonActionForm
                className="btn btn-primary m-3 w-200 h-100"
                label="Save model"
                value={true}
                type="submit"
            />
        </SmartForm>
    );
};

const ControlPanel = ({
    setNodes,
    products,
    messages,
    serviceModels,
    changeNodes,
    onDeleteNode,
    onResetNodes,
}) => {
    const nodes = useStoreState((store) => store.nodes);
    const edges = useStoreState((store) => store.edges);

    const selectedElements = useStoreState((store) => {
        return (
            store.selectedElements ||
            (store.nodes.length > 0 ? [store.nodes[0]] : null)
        );
    });

    return (
        <aside>
            <SelectedNode
                state={selectedElements}
                nodes={nodes}
                edges={edges}
                products={products}
                messages={messages}
                setNodes={setNodes}
                serviceModels={serviceModels}
                changeNodes={changeNodes}
                onDeleteNode={onDeleteNode}
                onResetNodes={onResetNodes}
            />
        </aside>
    );
};

export default ControlPanel;
